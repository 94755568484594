import React, { useState } from 'react';
import styled from 'styled-components';
import { GrMenu, GrClose } from 'react-icons/gr';
import Logo from './Logo';
import Nav from './Nav';

const HeaderStyles = styled.header`
  position: relative;
  padding: 20px 0 40px;
  background: var(--grey);
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .menuOpen {
    position: absolute;
    display: none;
    top: calc(50% - 15px);
    right: 20px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  @media (max-width: 767px) {
    padding: 10px 0 20px;
    .menuOpen {
      display: block;
    }
  }
`;

export default function Header() {
  const [isOpen, setIsOpen] = useState();

  const handleOpening = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <HeaderStyles className={isOpen ? 'opened' : ''}>
      <div className="container">
        <Logo />
        <Nav />
        <button type="button" className="menuOpen" onClick={handleOpening}>
          {!isOpen ? <GrMenu /> : <GrClose />}
        </button>
      </div>
    </HeaderStyles>
  );
}
