import React from 'react';
import 'normalize.css';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { useAuthValue } from './Auth/AuthContext';
import GlobalStyles from '../styles/Globalstyles';
import Typography from '../styles/Typography';
import Header from './Header';
import Footer from './Footer';
import FaqSection from './FaqSection';

const MainStyles = styled.main`
  padding: 60px 0 80px;
  @media (max-width: 767px) {
    padding: 40px 0;
  }
`;

export default function Layout({ children }) {
  const { currentUser } = useAuthValue();
  console.info(currentUser);

  if (typeof window !== 'undefined') {
    if (!currentUser) {
      navigate('/login');
      return null;
    }
  }

  return (
    <div>
      <GlobalStyles />
      <Typography />
      <Header />
      <MainStyles>
        <div className="container">{children}</div>
      </MainStyles>
      <FaqSection />
      <Footer />
    </div>
  );
}
