import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const BreadcrumbsStyles = styled.nav`
  margin-bottom: 5px;
  ul {
    display: flex;
  }
  li {
    :last-child {
    }
    :after {
      margin: 0 10px;
      content: '/';
    }
  }
  a {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
    :hover {
      color: var(--green);
    }
  }
  @media (max-width: 767px) {
    a {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

const SubPageHeaderStyles = styled.header`
  margin-bottom: 50px;
  &.center {
    h1 {
      text-align: center;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

export default function SubPageHeader({ title, breadcrumbs }) {
  return (
    <SubPageHeaderStyles className={breadcrumbs ? '' : 'center'}>
      {breadcrumbs && (
        <BreadcrumbsStyles>
          <ul>
            {breadcrumbs.map((el) => (
              <li key={el.title}>
                <Link to={el.url}>{el.title}</Link>
              </li>
            ))}
          </ul>
        </BreadcrumbsStyles>
      )}
      <h1>{title}</h1>
    </SubPageHeaderStyles>
  );
}
