import React from 'react';
import styled from 'styled-components';
import { GrFormUp } from 'react-icons/gr';

const FooterStyles = styled.footer`
  padding: 40px 0;
  background: var(--black);
  .container {
    position: relative;
    display: flex;
  }
  p {
    font-size: 14px;
    line-height: 16px;
    color: var(--white);
    font-weight: 500;
  }
  .jumpUp {
    position: absolute;
    top: calc(50% - 20px);
    right: 0;
    svg {
      width: 40px;
      height: 40px;
      polyline {
        stroke: white;
        transition: stroke 0.2s ease-in-out;
      }
    }
    :hover svg polyline {
      stroke: var(--green);
    }
  }
  @media (max-width: 767px) {
    padding: 20px 0;
    p {
      font-weight: 400;
    }
  }
`;

export default function Footer({ jump }) {
  const jumpUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <FooterStyles>
      <div className="container">
        <p>&copy; {new Date().getFullYear()} Altech Kachels </p>
        <button className="jumpUp" type="button" onClick={jumpUp}>
          <GrFormUp />
        </button>
      </div>
    </FooterStyles>
  );
}
