import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const FaqSectionStyles = styled.section`
  display: flex;
  width: 100%;
  height: 500px;
  background: var(--grey);
  justify-content: center;
  align-items: center;
  article {
    width: calc(100% - 80px);
    max-width: 750px;
    text-align: center;
  }
  h2 {
    text-align: center;
  }
  p {
    margin-top: 30px;
    font-family: 'RobotoSlab';
    font-size: 20px;
    line-height: 30px;
    color: var(--black);
    font-weight: 400;
    text-align: center;
  }
  a {
    display: inline-block;
    width: 300px;
    margin-top: 40px;
    padding: 20px;
    background: var(--black);
    border-radius: 5px;
    font-size: 16px;
    line-height: 20px;
    color: var(--white) !important;
    font-weight: 500;
    transition: background 0.2s ease-in-out;
    :hover {
      background: var(--green);
    }
  }
  @media (max-width: 767px) {
    display: none;
    height: 350px;
    article {
      width: calc(100% - 40px);
      max-width: 500px;
    }
    p {
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
    }
    a {
      width: 280px;
      max-width: calc(100% - 40px);
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export default function FaqSection() {
  return (
    <FaqSectionStyles>
      <article>
        <h2>Do you need more info?</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </p>
        <Link to="/faq">more</Link>
      </article>
    </FaqSectionStyles>
  );
}
