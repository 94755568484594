import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';

const NavStyles = styled.nav`
  margin-bottom: 12px;
  ul {
    display: flex;
    li {
      margin-right: 30px;
      :last-child {
        margin-right: 0;
      }
    }
  }
  a {
    font-size: 16px;
    line-height: 18px;
    color: var(--black);
    font-weight: 700;
    transition: color 0.2s ease-in-out;
    :hover {
      color: var(--green);
    }
  }
  @media (max-width: 999px) {
    ul li {
      margin-right: 20px;
    }
  }
  @media (max-width: 767px) {
    position: absolute;
    display: none;
    width: 100%;
    top: 100%;
    left: 0;
    padding: 40px 20px;
    background: var(--black);
    z-index: 1;
    ul {
      display: block;
      li {
        margin: 0 0 20px 0;
        text-align: center;
        :last-child {
          margin-bottom: 0;
        }
      }
    }
    a {
      color: var(--white);
    }
    header.opened & {
      display: block;
    }
  }
`;

export default function Nav() {
  const { nav } = useStaticQuery(graphql`
    query {
      nav: allSanityNav {
        nodes {
          name
          id
          url {
            current
          }
          active
        }
      }
    }
  `);
  const menu = nav.nodes;
  return (
    <NavStyles>
      <ul>
        {menu.map((item) =>
          item.active === true ? (
            <li key={item.id}>
              <Link to={`/${item.url.current}`}>{item.name}</Link>
            </li>
          ) : (
            ''
          )
        )}
      </ul>
    </NavStyles>
  );
}
