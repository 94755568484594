import React from 'react';
import Layout from '../components/Layout';
import SubPageHeader from '../components/SubPageHeader';

export default function OthersPage() {
  return (
    <Layout>
      <SubPageHeader title="Others" />
    </Layout>
  );
}
